<template>
   <div>
      <div>test</div>
      <div v-for="b in buckets" v-bind:key="b.Name">
         <p>{{ b.Name }}</p>
      </div>
   </div>
</template>
<script>
//import UserInfoStore from '../app/user-info-store';
import auth from '../app/auth';
//import AWS from 'aws-sdk';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
export default {
    name: 'Home',
    /*
    data: function() {
        var s = 'cognito-idp.ap-southeast-2.amazonaws.com/'+auth.USER_POOL_ID;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: auth.IDENTITY_POOL_ID,
            Logins: {
               [s]: auth.auth.getSignInUserSession().getAccessToken().jwtToken 
            }
         });


        return{
//            userInfo: auth.auth.getSignInUserSession().getAccessToken().jwtToken //UserInfoStore.state.cognitoInfo
            userInfo: auth.auth.getSignInUserSession().getIdToken() //UserInfoStore.state.cognitoInfo
        }
    }
    */
    data() {
        console.log("data");
        return {
            buckets:[]
        }
    },
    methods: {
        async getData() {
            console.log("getData");
            try {
                const response = await this.$http.get(API_ENDPOINT+"/s3", {
                   headers:{
                      'Authorization':auth.auth.getSignInUserSession().getIdToken().jwtToken
                   }
                });
                console.log(response);
                this.buckets = response.data.buckets.Buckets;
            } catch (error) {
               console.log(error);
            }
         },
    },
    created() {
       console.log("created");
       this.getData();
    },
    
}
</script>
<style>
</style>